import st from "./Modal.module.scss";
import {useEffect, useState} from "react";

type ModalType = {
    children: any,
    isShowed?: boolean,
    withCloseBtn?: boolean,
    onClose?: any
}

function Modal({children, isShowed = false, withCloseBtn = true, onClose}: ModalType) {
    const [isShow, setIsShowed] = useState(isShowed);
    useEffect(() => {
        setIsShowed(isShowed);
    }, [isShowed]);
    return (
        <div className={st.overlay + ' ' + (isShow ? '' : st.hidden)}>
            <div className={st.content}>
                {withCloseBtn ? (
                    <div className={st.close} onClick={() => {
                        if (onClose) {
                            onClose(false);
                        } else {
                            setIsShowed(false);
                        }
                    }}>
                    </div>) : ""
                }
                {children}</div>
        </div>
    );
}

export default Modal;