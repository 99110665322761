import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Main from "./templates/Main";
import AutoList from "./pages/AutoList";
import Page404 from "./pages/Page404";
import LoginForm from "./pages/LoginForm";
import AutoForm from "./pages/AutoForm";
import {ProtectedRoute} from "./middlewares/ProtectedRouter";
import axios from "axios";
import Contacts from "./pages/Contacts";
import ContactForm from "./pages/ContactForm";
import Orders from "./pages/Orders";
import OrderForm from "./pages/OrderForm";
import OrderShow from "./pages/OrderShow";
document.title = 'Dashboard';
function App() {
    //Чтобы куки отправлял по умолчанию. Иначе авторизация слетит
    axios.defaults.withCredentials = true;
    return (
        <BrowserRouter>

            <Routes>
                <Route path='/' element={<ProtectedRoute><Main>Dashboard soon</Main></ProtectedRoute>}/>
                <Route path='/contacts' element={<ProtectedRoute><Main><Contacts /></Main></ProtectedRoute>}/>
                <Route path='/contactForm/:contactId?' element={<ProtectedRoute><Main><ContactForm /></Main></ProtectedRoute>}/>
                <Route path='/orders' element={<ProtectedRoute><Main><Orders /></Main></ProtectedRoute>}/>
                <Route path='/orderForm/:orderId/:contactId?' element={<ProtectedRoute><Main><OrderForm /></Main></ProtectedRoute>}/>
                <Route path='/orderShow/:orderId' element={<ProtectedRoute><Main><OrderShow /></Main></ProtectedRoute>}/>
                <Route path='/cars' element={<ProtectedRoute><Main><AutoList/></Main></ProtectedRoute>}/>
                <Route path='/company' element={<ProtectedRoute><Main>My company soon</Main></ProtectedRoute>}/>
                <Route path='/login' element={<LoginForm/>}/>
                <Route path='/autoform/:carId?' element={<ProtectedRoute><Main><AutoForm/></Main></ProtectedRoute>}/>
                <Route path="*" element={<Page404/>}/>
            </Routes>


        </BrowserRouter>
    )
}

export default App;
