import React from "react";

function page404() {
    return (
        <>
            404
        </>
    );

}

export default page404;