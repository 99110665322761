import st from "../styles/template.module.scss";
import React, {ChangeEvent, useEffect, useState} from "react";

import CSelect from "../components/Cselect/CSelect";
import axios from "axios";
import {API} from "../const/API";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../components/Modal/Modal";

import {IOrder} from "../types/Order";
import {IColor} from "../types/Color";
import Checkbox from "../components/Checkbox/Checkbox";

import {IOrderState} from "../types/OrderState";
import {ICity} from "../types/City";
import {IBaseObject} from "../types/BaseObject";

const WorkStatusID = 1;
let searchTimeOut: any;
const DFields = ['order_state_id', 'close_reason_id',
    'close_comments', 'pickup_location', 'drop_location', 'driver_age', 'driver_exp',
    'driver_nationality_id', 'price_from', 'price_to', 'price_currency_id', 'engine_from', 'engine_to', 'categories_list',
    'classes_list', 'brands_list', 'models_list', 'transmissions_list', 'drive_types_list', 'fuel_types_list',
    'seats_list', 'body_colors_list', 'interior_colors_list', 'partners_list', 'uid_partner_request',
    'ts_partner_request', 'ref_vehicle_id', 'client_vehicles_list', 'resp_user_code', 'ts_created'];
const initialSeats = [{name: "1", code: "1", id: "1"}, {name: "2", code: "2", id: "2"},
    {name: "3", code: "3", id: "3"}, {name: "4", code: "4", id: "4"}, {name: "5", code: "5", id: "5"},
    {name: "6", code: "6", id: "6"}, {name: "7", code: "7", id: "7"}, {name: "8", code: "8", id: "8"},
    {name: "9", code: "9", id: "9"}, {name: "10", code: "10", id: "10"}, {name: "11", code: "11", id: "11"},
    {name: "12", code: "12", id: "12"}];

export default function OrderShow() {
    const navigate = useNavigate();
    let {contactId, orderId} = useParams();
    const [cities, setCities] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [error, setError] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [order, setOrder] = useState({contact_id: 0} as IOrder)
    const [errorMessage, setErrorMessage] = useState("");
    const [currencies, setCurrencies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [classes, setClassses] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [transmissions, setTransmissions] = useState([]);
    const [drive_types, setDrive_types] = useState([]);
    const [fuel_types, setFuel_types] = useState([]);
    const [seats, setSeats_list] = useState(initialSeats);
    const [colors, setColors] = useState(Array<IColor>);
    const [bodyColors, setBodyColors] = useState(Array<IColor>);
    const [interiorColors, setInteriorColors] = useState(Array<IColor>);
    const [partners, setPartners] = useState([]);
    const [filteredPartners, setFilteredPartners] = useState([]);
    const [orderState, setOrderState] = useState({} as IOrderState);

    useEffect(() => {
        if (!orderId || orderId === "0") {
            setOrder((draft) => {
                if (contactId) {
                    draft.contact_id = parseInt(contactId);
                    draft.order_state_id = 1;
                    return JSON.parse(JSON.stringify(draft));
                }
            })
        } else {
            axios.get(API + '/Order/' + orderId + '/').then((response) => {
                if (response?.data) {
                    setOrder(response.data);
                }
            }).catch((e) => {
                setError(true);
            });
        }

        //национальности
        axios.get(API + '/nationalities/').then((response) => {
            if (response?.data) {
                setNationality(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
        //categories_list: []
        axios.get(API + '/Categories/').then((response) => {
            if (response?.data) {
                setCategories(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //classes_list: []
        axios.get(API + '/Classes/').then((response) => {
            if (response?.data) {
                setClassses(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //brands_list: []
        axios.get(API + '/brands/').then((response) => {
            if (response?.data) {
                setBrands(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //models_list: []
        axios.get(API + '/models/').then((response) => {
            if (response?.data) {
                setModels(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //transmissions_list: []
        axios.get(API + '/transmissions/').then((response) => {
            if (response?.data) {
                setTransmissions(response.data);
                                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //drive_types_list: []
        axios.get(API + '/drivetypes/').then((response) => {
            if (response?.data) {
                setDrive_types(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //fuel_types_list: []
        axios.get(API + '/fueltypes/').then((response) => {
            if (response?.data) {
                setFuel_types(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });

        //body_colors_list: []
        //interior_colors_list: []
        axios.get(API + '/colors/').then((response) => {
            if (response?.data) {
                setColors(response.data);
                setBodyColors(response.data);
                setInteriorColors(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //partners_list: []
        axios.get(API + '/Counterparties/').then((response) => {
            if (response?.data) {
                setPartners(response.data);
                //setFilteredPartners([]);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });


        //валюты
        axios.get(API + '/currencies/').then((response) => {
            if (response?.data) {
                setCurrencies(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });


        //языки
        axios.get(API + '/languages/').then((response) => {
            if (response?.data) {
                response.data.forEach((item: { id: any; code: any; }) => {
                    item.id = item.code;
                })
                setLanguages(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //города
        axios.get(API + '/cities/').then((response) => {
            if (response?.data) {
                setCities(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });


    }, []);

    return (<>
        <div className={st.body_panel_heading}>
            <div className={'f24'}>Order</div>
            <div className={st.body_panel_heading_control}>
                {orderState.name}
            </div>
        </div>
        <div className={st.body_panel_body}>
            <div className={st.cube2}>
                <div>
                    <div className={st.cube2}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>Rent start from:</div>
                                <div>{order?.ts_from}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>Duration:</div>
                                <div>{order?.duration}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>City:</div>
                                <div>{cities.map((c: ICity) => {
                                    if (c.id == order.city_id) {
                                        return c.name;
                                    }
                                })}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>Language:</div>
                                <div>{languages.map((c: IColor) => {
                                    if (c.code == order.comm_lang_code) {
                                        return c.name;
                                    }
                                })}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>Pickup location:</div>
                                <div>{order?.pickup_location}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>Drop location:</div>
                                <div>{order?.drop_location}</div>
                            </div>
                        </div>

                    </div>
                    <div className={st.cube3}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>driver age:</div>
                                <div>{order?.driver_age}</div>

                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>driver experience:</div>
                                <div>{order?.driver_exp}</div>

                            </div>
                        </div>

                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>driver nationality:</div>
                                <div>{nationality.map((c: any) => {
                                    if (c.id == order.driver_nationality_id) {
                                        return c.name;
                                    }
                                })}
                                    {order.driver_nationality_id === null ? "-" : ""}</div>

                            </div>
                        </div>
                    </div>
                    <div className={st.cube2}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>Price:</div>
                                <div>{order?.price_from > 0 ? order?.price_from : 0} - {order?.price_to} {currencies.map((c: any) => {
                                    if (c.id == order.price_currency_id) {
                                        return c.symbol;
                                    }
                                })}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>Engine:</div>
                                <div>{order?.engine_from} - {order?.engine_to}</div>

                            </div>
                        </div>
                    </div>

                    <div className={st.cube2}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>categories:</div>
                                <div>{order.categories_list && categories.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.categories_list && (order.categories_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}
                                    {order.categories_list === null ? "-" : ""}</div>

                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>classes:</div>
                                <div>{order.classes_list && classes.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.classes_list && (order.classes_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}
                                    {order.classes_list === null ? "-" : ""}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>brands:</div>
                                <div>{order.brands_list && brands.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.brands_list && (order.brands_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}
                                    {order.brands_list === null ? "-" : ""}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>models:</div>
                                <div>{order.models_list && models.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.models_list && (order.models_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}
                                    {order.models_list === null ? "-" : ""}</div>

                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>transmissions:</div>
                                <div>{order.transmissions_list && transmissions.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.transmissions_list && (order.transmissions_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}
                                    {order.transmissions_list === null ? "-" : ""}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>drive types:</div>
                                <div>{order.drive_types_list && drive_types.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.drive_types_list && (order.drive_types_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}
                                    {order.drive_types_list === null ? "-" : ""}</div>

                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>fuel types:</div>
                                <div>{order.fuel_types_list && fuel_types.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.fuel_types_list && (order.fuel_types_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}
                                    {order.fuel_types_list === null ? "-" : ""}
                                </div>

                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>seats:</div>
                                <div>{seats.map((c: any) => {
                                    let t = [];
                                    if (order.seats_list && (order.seats_list as Array<number>).includes(c.id)) {
                                        t.push(c.name);
                                    }
                                    return t;
                                })}</div>

                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>body colors:</div>
                                <div>{order.body_colors_list && colors.map((c: IBaseObject) => {
                                    if ((order.body_colors_list as Array<string>).includes(c.id.toString())) {
                                        return c.name + " ";
                                    }

                                })}
                                    {order.body_colors_list === null ? "-" : ""}</div>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.showBlock}>
                                <div>interior colors:</div>
                                <div>{order.interior_colors_list && colors.map((c: IBaseObject) => {
                                    let t = [];
                                    if (order.interior_colors_list && (order.interior_colors_list as Array<string>).includes(c.id.toString())) {
                                        t.push(c.name+" ");
                                    }
                                    return t;
                                })}
                                    {order.interior_colors_list === null ? "-" : ""}</div>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className={st.row + ' ' + st.row_compact}>
                        <div className={st.showBlock}>
                            <div>partners:</div>
                            <div className={st.cube2}>
                                {filteredPartners && filteredPartners.map((item) => (
                                    <div key={(item as any).id} className={st.flex + ' ' + st.flexRight}>
                                        {(item as any).name}
                                    </div>

                                ))}
                                {filteredPartners.length < 1 && ("-")}
                            </div>
                        </div>

                    </div>
                    <div className={st.row + ' ' + st.row_compact}>
                        <div className={st.showBlock}>
                            <div>vehicles</div>
                            AWAIT REAL CARS
                            {/*<DataTable*/}
                            {/*    columns={[*/}
                            {/*        {*/}
                            {/*            name: 'ID',*/}
                            {/*            id: 'id_',*/}
                            {/*            field: 'id'*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            name: 'carName',*/}
                            {/*            id: 'carName_',*/}
                            {/*            render: (car: any) => {*/}
                            {/*                return car.brand_name + ',' + car.model_name + (car.extra_txt ? "," + car.extra_txt : "");*/}
                            {/*            }*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            name: 'year',*/}
                            {/*            id: 'year_',*/}
                            {/*            field: 'year'*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            name: 'client_price',*/}
                            {/*            id: 'client_price_',*/}
                            {/*            field: 'client_price'*/}
                            {/*        },*/}
                            {/*        {*/}
                            {/*            name: 'carPrice',*/}
                            {/*            id: 'carPrice_',*/}
                            {/*            render: (car: any) => {*/}
                            {/*                return order.price_currency_id * 1 > 0 ? (currencies.filter((i) => {*/}
                            {/*                    return (i as any).id === order.price_currency_id*/}
                            {/*                })[0] as any).code : ""*/}
                            {/*            }*/}
                            {/*        },*/}
                            {/*    ]}*/}

                            {/*    rowsPerPage={7}*/}
                            {/*    data={vehicles}*/}
                            {/*    numPaginationButtons={5}*/}
                            {/*    additionalButtons={true}*/}
                            {/*    classForTable={st.body_panel_body}/>*/}

                        </div>
                    </div>
                </div>
            </div>

            <div className={st.row + ' ' + st.flexRight}>
                <Modal isShowed={error} onClose={setError} withCloseBtn={true}>
                    {errorMessage}</Modal>
            </div>

        </div>
    </>);
}