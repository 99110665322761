import React, {ChangeEvent, useEffect, useState} from "react";
import axios from "axios";
import {API} from "../const/API";
import st from "../styles/template.module.scss";
import {IContact} from "../types/Contact";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import {NavLink} from "react-router-dom";
import {IContactsState} from "../types/ContactsState";
import CSelect from "../components/Cselect/CSelect";
import {optionType} from "../components/Cselect/types/optionTypes";
import DataTable from "../components/DataTable/DataTable";
import EditableBlock from "../components/EditableBlock/EditableBlock";
import icoOnSite from "../imgs/Icon-site.svg";
import icoEdit from "../imgs/Icon-Edit.svg";
import icoSearch from "../imgs/search-normal.svg";
import icoDelete from "../imgs/Icon-Delete.svg";
import Modal from "../components/Modal/Modal";
import ContactsDetails from "./parts/ContactDetails";

let searchTimeOut: any;

export default function Contacts() {

    const [contacts, setContacts] = useState<Array<IContact>>([]);
    const [selectedStatus, setSelectedStatus] = useState("-");
    const [searchText, setSearchText] = useState('');
    const [contactStates, setContactStates] = useState<Array<IContactsState>>([]);
    const [stateOptions, setStateOptions] = useState<Array<optionType>>([]);
    const [error, setError] = useState(false);
    const [showedContact, setShowedContact] = useState(0);

    useEffect(() => {
        axios.get(API + '/contacts/').then((response) => {
            if (response?.data) {
                setContacts(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/ContactsStates/').then((response) => {
            if (response?.data) {
                setContactStates(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
    }, []);


    useEffect(() => {
        setStateOptions((obj) => {
            obj = [];
            contactStates.forEach((item, index) => {
                let objOption = {code: item.name, id: item.id, name: item.name};
                obj.push(objOption as any);
            })
            return obj;
        })
    }, [contactStates])


    useEffect(() => {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
        }
        let status = isNaN(parseInt(selectedStatus)) ? "" : selectedStatus + "/";

        searchTimeOut = setTimeout(() => {
            axios.post(API + '/SearchContacts/' + status, {
                text: searchText,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if (response?.data) {
                    setContacts(response.data);
                }
            }).catch((e) => {
                setError(true);
            });
        }, 300);
    }, [searchText, selectedStatus])


    return (
        <> {error ? "ERROR" : ""}
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Contacts</div>
                <div className={st.body_panel_heading_control}>
                    <div className={st.flex + ' ' + st.gap8} style={{width: 'max-content', minWidth: 180 + "px"}}>
                        <CSelect value={selectedStatus} onChange={(obj: ChangeEvent<HTMLInputElement>) => {
                            setSelectedStatus(obj?.target?.value);
                        }} name={'state'} isSelect2={true} values={stateOptions} placeHolder={'select state'}/>
                    </div>
                    <Input name={'search'} value={searchText} onChange={(evt) => setSearchText(evt.target.value)}
                           placeholder={'type contact'}/>
                    <NavLink to={'/contactForm/'}><Button color={"blue"} type={"plus"}>Add
                        contact</Button></NavLink>
                </div>
            </div>
            <div className={st.body_panel_body}>
                <Modal isShowed={showedContact > 0} onClose={() => setShowedContact(0)}>
                    <ContactsDetails id={showedContact}/>
                </Modal>
            </div>
            <DataTable
                columns={[
                    {
                        name: '#',
                        id: 'id_',
                        field: 'id'
                    },
                    {
                        name: 'FIO',
                        id: 'first_name_',
                        render: (item: any) => {
                            return <>{item.first_name} {item.middle_name} {item.last_name}</>;
                        }
                    },
                    {
                        name: 'Status',
                        id: 'contact_state_id_',
                        render: (item: any) => {
                            let status = null;
                            contactStates.map((st) => {
                                if (st.id == item.contact_state_id) {
                                    status = st.name;
                                }
                            })
                            return status;
                        }
                    },
                    {
                        name: 'Lead from',
                        id: 'lead_source_code_',
                        field: "lead_source_code"
                    },
                    {
                        name: 'Birth date',
                        id: 'birth_date_',
                        field: "birth_date"
                    },
                    {
                        name: 'Orders[act/boo/fai]',
                        id: 'orders_',
                        render: (item: any) => {
                            return <>{item.active_orders}/{item.booked_orders}/{item.failed_orders}</>;
                        }
                    },

                    {
                        name: 'Control',
                        id: 'control',
                        render: (item: any) => {
                            return <><NavLink to={'/contactForm/' + item.id}><img src={icoEdit}
                                                                                  alt={'edit row'}/></NavLink>
                                <NavLink to={'/orderForm/0/' + item.id}><img src={icoOnSite}
                                                                             alt={'add order'}/></NavLink>
                                {(item.active_orders > 0 || item.booked_orders > 0 || item.failed_orders > 0) ?
                                    <img src={icoSearch} onClick={() => setShowedContact(item.id)}
                                         alt={'Show Order'}/> : <></>}
                            </>;
                        }
                    }]}

                rowsPerPage={50}
                data={contacts}
                numPaginationButtons={5}
                additionalButtons={true}
                classForTable={st.body_panel_body}/>

        </>
    );
}