import st from './Textarea.module.scss';

type TextareaType = {

    placeholder?: string;
    value?: any;
    onChange?: (value: any) => void;
    name?: string;

}

function Textarea({placeholder = '', name, value, onChange}: TextareaType) {
    return (
        <textarea className={st.textarea} placeholder={placeholder}
                  onChange={onChange} name={name} value={value} />
    );
}

export default Textarea