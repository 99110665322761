import st from "../styles/template.module.scss";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import DataTable from "../components/DataTable/DataTable";
import React, {useEffect} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useImmer} from "use-immer";
import axios from "axios";
import icoEdit from "./../imgs/Icon-Edit.svg";
import icoDelete from "./../imgs/Icon-Delete.svg";
import icoOnSite from "./../imgs/Icon-site.svg";
import {API} from "../const/API";
import Modal from "../components/Modal/Modal";
import EditableBlock from "../components/EditableBlock/EditableBlock";

type priceType = {
    days: string,
    caption: string,
    value: string,
}


function AutoList() {
    /* Чтобы работала сессия */
    axios.defaults.withCredentials = true;
    const [depositBulk, setDepositBulk] = useImmer(0);
    /* Перечень цен в колонках и в bulk всплывашке */
    const [userPrices, setUserPrices] = useImmer([]);
    /* массив выбарнны строк */
    const [selectedRows, setSelectedRows] = useImmer([]);
    /* Отображать поля для ввода массовой цены ? */
    const [showBulkEdit, setShowBulkEdit] = useImmer(false);
    /* Отображать поля для ввода массового депозита ? */
    const [showDepositEdit, setShowDepositEdit] = useImmer(false);
    /* Перечень всех авто */
    const [data, setVehicles] = useImmer([]);
    /* Перечень колонок по которым осуществляется поиск */
    const [searchColumns, setSearchColumns] = useImmer([]);
    /* Отфильтрованная дата (чтобы основная не трогалась) */
    const [filteredData, setFiltered] = useImmer([]);
    /* Текст по которому ищем ? */
    const [searchText, setSearchText] = useImmer('');
    /* Навигация */
    const nav = useNavigate();

    let searchObj: any = [];
    let searchFields: Array<{ key: string, name: string, value: string, style?: object }>;


    useEffect(() => {
        /* Если это партнер, то у него код 202 и можем искать по нему */
        if (sessionStorage.getItem('user') === '202') {
            axios.get(API + '/prices/').then((response) => {
                sessionStorage.setItem("def_currency_id", response.data.def_currency_id);
                let symbol = sessionStorage.getItem('def_currency_symbol');
                if (!symbol){
                    axios.get(API + '/CurrencySymbol/'+response.data.def_currency_id+'/').then((resp) => {
                        sessionStorage.setItem("def_currency_symbol", resp.data.symbol);
                    });

                }
                document.title = 'Cars - '+response.data.name;
                let uPrices = JSON.parse(response.data.price_pattern);
                searchFields = [];
                uPrices.forEach((price: priceType) => {
                    searchFields.push({key: price.days, name: price.caption, value: "", style: {width: '70px', textTransform: 'capitalize'}});
                })
                setUserPrices(uPrices);
                searchObj = [
                    {
                        name: '',
                        id: 'img0',
                        style: {width: '60px'},
                        render: ((item: any) => {
                            if (item.img) {
                                //обратите внимание, что сюда API должен вернуть полный URL на картинку 40px!
                                return <img className={'img60'} src={'https://rent.cars' + item.img}/>;
                            } else {
                                return '';
                            }

                        })
                    },
                    {
                        name: 'Model',
                        id: 'name0',
                        field: 'firstColumn'
                    },
                    {
                        name: 'Category',
                        id: 'second0',
                        field: "secondColumn"
                    },

                    {
                        "name": searchFields, //тут у нас массив!
                        id: 'prices0',
                        render: (item: any, field: any) => {
                            let price = JSON.parse(item.item.price_options).price;
                            let result = "";
                            let days = 0;
                            if (price) {
                                price.forEach((priceItem: { days: string, value: string }) => {
                                    if (field.key == priceItem.days) {
                                        result = priceItem.value;
                                        days = field.key;
                                    }
                                })
                            }
                            return <EditableBlock key={item.item.id + "_" + days+"=_d"} keyp={item.item.id + "_" + days} val={result}
                                                  callBack={loadVehicles}/>;
                        }
                    },
                    {
                        name: 'Deposit',
                        id: 'Deposit0',
                        field: "deposit"
                    },
                    {
                        name: 'URL',
                        id: 'site',
                        render: (item: any) => {
                            return <a href={'https://rent.cars/cars' + item.url + '/'} target={'_blank'}> <img
                                src={icoOnSite} alt={'go to site'}/></a>;
                        }
                    },
                    {
                        name: 'Control',
                        id: 'control',
                        render: (item: any) => {
                            return <><NavLink to={'/autoform/' + item.id}>
                                <img src={icoEdit} alt={'edit row'}/></NavLink><img
                                src={icoDelete} className={st.cp + ' '+st.ml5} alt={'delete row'} onClick={() => {
                                removeVehicle(item.id);
                            }}/></>;
                        }
                    }];
                setSearchColumns(searchObj);

            }).catch((e) => console.log(e));
        } else {
            /* Перечень колонок для админа */
            searchObj = [
                {
                    name: 'id',
                    id: 'id0',
                    field: 'id'
                },
                {
                    name: 'Model',
                    id: 'name0',
                    field: 'firstColumn'
                },
                {
                    name: 'Category',
                    id: 'second0',
                    field: "secondColumn"
                },
                {
                    name: 'Counterparty',
                    id: 'third0',
                    field: "thirdColumn"
                },
                {
                    name: 'Deposit',
                    id: 'Deposit0',
                    field: "deposit"
                },
                {
                    name: 'URL',
                    id: 'site',
                    render: (item: any) => {
                        return <a href={'https://rent.cars/cars' + item.url + '/'} target={'_blank'}> <img
                            src={icoOnSite} alt={'go to site'}/></a>;
                    }
                },
                {
                    name: 'Control',
                    id: 'control',
                    render: (item: any) => {
                        return <><NavLink to={'/autoform/' + item.id}><img src={icoEdit}
                                                                           alt={'edit row'}/></NavLink><img
                            src={icoDelete} className={st.cp+' '+st.ml5} alt={'delete row'} onClick={() => {
                            removeVehicle(item.id);
                        }}/></>;
                    }
                }];
            setSearchColumns(searchObj);
        }
        loadVehicles();
    }, []);

    const loadVehicles = () => {
        axios.get(API + '/vehicles/').then((response) => {
            setVehicles(response.data);
            setFiltered(response.data);
        }).catch((e) => {
            if (e.response.status === 401) {
                sessionStorage.removeItem("user");
                nav('/login');
            }
        });
    }

    /* удаляет авто */
    const removeVehicle = function (id: any) {
        axios.get(API + '/DeleteVehicle/' + id + '/').then((response) => {
            setFiltered((draft => {
                return data.filter((item: any) => {
                    return !(item.id + "" === id + "");
                })
            }))
        }).catch((e) => {
            alert(e.response.data.data);
        });
    }
    /* устанавливает цену текущего дня */
    const setPriceBulkValue = (days: string, evt: React.ChangeEvent<HTMLInputElement>) => {
        setUserPrices((draft) => {
            draft.forEach((item: priceType) => {
                if (item.days == days) {
                    item.value = evt.target.value;
                }
            })
        });
    }
    /* возвращает цену для указанного дня */
    const getPriceForBulkByDays = (days: string) => {
        let val: any;
        userPrices.forEach((item: priceType) => {
            if (item.days === days) {
                if (item.value) {
                    val = item.value;
                } else {
                    val = "";
                }
            }
        })
        return val;
    }
    const setBulkPrice = () => {
        axios.post(API + '/setBulkPrice/', {
            ids: selectedRows,
            values: userPrices
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            loadVehicles();
            setShowBulkEdit(false);
        }).catch((e) => {
            alert(e);
        });
    }
    const setBulkDeposit = () => {
        axios.post(API + '/setBulkDeposit/', {
            ids: selectedRows,
            value: depositBulk
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            loadVehicles();
            setShowDepositEdit(false);
        }).catch((e) => {
            // console.log(e.response.data.data);
            alert(e.response.data.data);
        });
    }

    return (
        <>

            {/* Окно с информацией о кол-ве выбранных элементов */}
            {userPrices && userPrices.length > 0 ? (
                <div key={'editLine'}
                     className={st.popup + ' ' + (selectedRows.length > 0 && !showBulkEdit && !showDepositEdit ? '' : st.hidden)}>
                    <div>{selectedRows.length} items selected</div>
                    <Button color={'blue'} onClick={() => setShowBulkEdit(true)}>Bulk price edit</Button>
                    <Button color={'blue'} onClick={() => setShowDepositEdit(true)}>Bulk deposit edit</Button>
                    <div className={st.closeButton} onClick={() => setSelectedRows([])}></div>
                </div>) : ""}
            <Modal key={'showDepositEdit'} isShowed={showDepositEdit}>
                <div className={st.flex + ' ' + st.w100 + ' ' + st.gap8}>
                    <div className={'f24'}>Bulk deposit edit</div>
                    <div className={st.closeButton} onClick={() => {
                        setShowDepositEdit(false)
                    }}></div>
                </div>
                <div className={st.flex + ' ' + st.gap8 + ' ' + st.w100}>
                    <Input name={'bulkDeposit'} value={depositBulk}
                           onChange={(evt) => setDepositBulk(evt.target.value)}/>
                </div>
                <div className={st.flex + ' ' + st.w100}>
                    <Button color={"light-blue"} className={st.w100} onClick={() => {
                        setShowDepositEdit(false)
                    }}>Cancel</Button>
                    <Button color={"blue"} className={st.w100} onClick={() => {
                        setBulkDeposit();
                    }}>Save changes</Button>
                </div>
            </Modal>
            {/* /Окно с информацией о кол-ве выбранных элементов */}
            {userPrices && userPrices.length > 0 ? (
                <Modal key={'showBulkEdit'} isShowed={showBulkEdit}>
                    <div key={'ShoBulkEditButtonModal'} className={st.flex + ' ' + st.w100}>
                        <div className={'f24'}>Bulk price edit</div>
                        <div key={'ShoBulkEditButton'} className={st.closeButton} onClick={() => {
                            setShowBulkEdit(false)
                        }}></div>
                    </div>
                    <div className={st.flex + ' ' + st.gap8 + ' ' + st.w100}>
                        {
                            userPrices.map((price: priceType) => {
                                return (
                                    <div className={st.flex + ' ' + st.flexCol + ' ' + st.gap8}>
                                        <div className={'f14'}>{price.caption}</div>
                                        <div><Input name={'price____' + price.days}
                                                    value={getPriceForBulkByDays(price.days)}
                                                    onChange={(value) => setPriceBulkValue(price.days, value)}/></div>
                                    </div>);
                            })
                        }
                    </div>
                    <div className={st.flex + ' ' + st.w100}>
                        <Button color={"light-blue"} className={st.w100} onClick={() => {
                            setShowBulkEdit(false)
                        }}>Cancel</Button>
                        <Button color={"blue"} className={st.w100} onClick={setBulkPrice}>Save changes</Button>
                    </div>
                </Modal>) : <></>}
            {/* Основная панель листинга */}
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Cars</div>
                <div className={st.body_panel_heading_control}>
                    <Input ico={'search'} placeholder={'search car'} value={searchText} onChange={(e) => {
                        setSearchText(e.target.value);
                        if (e.target.value.length > 0) {
                            setFiltered((draft => {
                                return data.filter((item) => {
                                    let contain = false;
                                    if (typeof item === "object") {
                                        Object.entries(item).forEach((value) => {
                                            if (typeof value[1] === "string") {
                                                if ((value[1] as string).toLowerCase().includes(e.target.value.toLowerCase()) && !contain) {
                                                    contain = true;
                                                }
                                            }
                                        })
                                    }
                                    return contain;
                                })

                            }))
                        } else {
                            setFiltered(data);
                        }
                    }}/>
                    <Link to={'/autoform'}><Button color={'blue'} type={'plus'}>Add car</Button></Link>
                </div>

            </div>
            {/* /Основная панель листинга */}
            {/* Листинг */}
            {data.length >= 1 && searchColumns.length > 0 ? (
                <DataTable
                    columns={
                        searchColumns
                    }
                    isSelectRows={userPrices && userPrices.length > 0}
                    rowsPerPage={50}
                    data={filteredData}
                    numPaginationButtons={5}
                    additionalButtons={true}
                    onSelect={setSelectedRows}
                    classForTable={st.body_panel_body}/>
            ) : (<div className={st.body_panel_body + ' ' + st.form}>
                <div className={st.row}>LOADING</div>
            </div>)}
            {/* / Листинг */}
        </>


    );
}

export default AutoList;