import st from './RadioButton.module.scss';
import {memo} from "react";

type RadioType = {
    value?: any;
    onChange?: (obj: any) => void;
    isChecked?: boolean
    onClick?: any

}

function RadioButton({value = '', onChange, isChecked = false, onClick}: RadioType) {
    return (
        <>
            <input type={"radio"} className={st.radio} value={value} checked={isChecked} onClick={onClick}
                   onChange={onChange ? onChange : () => {
                       return;
                   }}/>
        </>
    );
}

export default memo(RadioButton)
