import st from "../styles/template.module.scss";
import React, {ChangeEvent, useEffect, useState} from "react";
import Input from "../components/Input/Input";
import CSelect from "../components/Cselect/CSelect";
import axios from "axios";
import {API} from "../const/API";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Modal from "../components/Modal/Modal";
import Button from "../components/Button/Button";
import {IOrder} from "../types/Order";
import {IColor} from "../types/Color";
import Checkbox from "../components/Checkbox/Checkbox";
import icoEdit from "../imgs/Icon-Edit.svg";
import DataTable from "../components/DataTable/DataTable";
import {IOrderState} from "../types/OrderState";

const WorkStatusID = 1;
let searchTimeOut: any;
const DFields = ['order_state_id', 'close_reason_id',
    'close_comments', 'pickup_location', 'drop_location', 'driver_age', 'driver_exp',
    'driver_nationality_id', 'price_from', 'price_to', 'price_currency_id', 'engine_from', 'engine_to', 'categories_list',
    'classes_list', 'brands_list', 'models_list', 'transmissions_list', 'drive_types_list', 'fuel_types_list',
    'seats_list', 'body_colors_list', 'interior_colors_list', 'partners_list', 'uid_partner_request',
    'ts_partner_request', 'ref_vehicle_id', 'client_vehicles_list', 'resp_user_code', 'ts_created'];
const initialSeats = [{name: "1", code: "1", id: "1"}, {name: "2", code: "2", id: "2"},
    {name: "3", code: "3", id: "3"}, {name: "4", code: "4", id: "4"}, {name: "5", code: "5", id: "5"},
    {name: "6", code: "6", id: "6"}, {name: "7", code: "7", id: "7"}, {name: "8", code: "8", id: "8"},
    {name: "9", code: "9", id: "9"}, {name: "10", code: "10", id: "10"}, {name: "11", code: "11", id: "11"},
    {name: "12", code: "12", id: "12"}];

export default function OrderForm() {
    const navigate = useNavigate();
    let {contactId, orderId} = useParams();
    const [cities, setCities] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [error, setError] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [order, setOrder] = useState({contact_id: 0} as IOrder)
    const [errorMessage, setErrorMessage] = useState("");
    const [currencies, setCurrencies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [classes, setClassses] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [brands, setBrands] = useState([]);
    const [filteredBrands, setFilteredBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [transmissions, setTransmissions] = useState([]);
    const [filteredTransmissions, setFilteredTransmissions] = useState([]);
    const [drive_types, setDrive_types] = useState([]);
    const [filteredDrive_types, setFilteredDrive_types] = useState([]);
    const [fuel_types, setFuel_types] = useState([]);
    const [filteredFuel_types, setFilteredFuel_types] = useState([]);
    const [seats, setSeats_list] = useState(initialSeats);
    const [filteredSeats, setFilteredSeats_list] = useState(initialSeats);
    const [colors, setColors] = useState(Array<IColor>);
    const [bodyColors, setBodyColors] = useState(Array<IColor>);
    const [interiorColors, setInteriorColors] = useState(Array<IColor>);
    const [partners, setPartners] = useState([]);
    const [filteredPartners, setFilteredPartners] = useState([]);
    const [disabledFields, setDisabledFields] = useState(DFields);
    const [cntFields, setCntFields] = useState({});
    const [orderState, setOrderState] = useState({} as IOrderState);

    const validateAndSubmit = () => {
        axios.post(API + '/SaveOrder/', {
            ...order
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            navigate('/orders');
        }).catch((e) => {
            setErrorMessage(e.response.data.data);
            setError(true);
        });


    }

    //если меняется заказ, пересчитаем параметры
    useEffect(() => {
        //статусы заказа
        axios.get(API + '/OrderState/' + order.order_state_id + '/').then((response) => {
            if (response?.data) {
                setOrderState(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        if (order?.city_id > 0 && order?.duration > 0 && order.comm_lang_code && order.comm_lang_code.length > 1 && order.ts_from && order?.ts_from.length > 5) {
            if (searchTimeOut) {
                clearTimeout(searchTimeOut);
            }
            searchTimeOut = setTimeout(() => {
                axios.post(API + '/SearchAnCount/', {
                    order
                }, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    let data = response.data.data;
                    setCntFields(data);

                    if (data.body_colors_list) {
                        setBodyColors((draft) => {
                            return fiterD(JSON.parse(JSON.stringify(colors)), 'body_colors_list', data);
                        })
                    } else {
                        setBodyColors([])
                    }
                    if (data.interior_colors_list) {
                        setInteriorColors((draft) => {
                            return fiterD(JSON.parse(JSON.stringify(colors)), 'interior_colors_list', data);
                        })
                    } else {
                        setInteriorColors([]);
                    }
                    if (data.vehicles_list) {
                        setVehicles(data.vehicles_list);
                    } else {
                        setVehicles([]);
                    }
                    if (data.brands_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(brands)), 'brands_list', data);
                        setFilteredBrands(d as any);
                    } else {
                        setFilteredBrands([]);
                    }
                    if (data.categories_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(categories)), 'categories_list', data);
                        setFilteredCategories(d as any);
                    } else {
                        setFilteredCategories([]);
                    }
                    if (data.classes_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(classes)), 'classes_list', data);
                        setFilteredClasses(d as any);
                    } else {
                        setFilteredClasses([]);
                    }
                    if (data.fuel_types_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(fuel_types)), 'fuel_types_list', data);
                        setFilteredFuel_types(d as any);
                    } else {
                        setFilteredFuel_types([]);
                    }
                    if (data.models_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(models)), 'models_list', data);
                        setFilteredModels(d as any);
                    } else {
                        setFilteredModels([]);
                    }
                    if (data.transmissions_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(transmissions)), 'transmissions_list', data);
                        setFilteredTransmissions(d as any);
                    } else {
                        setFilteredTransmissions([]);
                    }
                    if (data.drive_types_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(drive_types)), 'drive_types_list', data);
                        setFilteredDrive_types(d as any);
                    } else {
                        setFilteredDrive_types([]);
                    }
                    if (data.partners_list) {
                        let d = fiterD(JSON.parse(JSON.stringify(partners)), 'partners_list', data);
                        setFilteredPartners(d as any);
                    } else {
                        setFilteredPartners([]);
                    }


                }).catch((e) => {
                    console.log("ERROR: 82");
                });
            }, 400);
        }
    }, [order])

    const fiterD = (array: [], name: string, data: any) => {
        let mainArray = JSON.parse(JSON.stringify(array));
        let timeArray = [];
        for (let i = 0; i < mainArray.length;) {
            if (typeof data[name] == "undefined") {
                data[name] = [];
            }
            let findInResult = data[name].find((obj: any) => obj.key === mainArray[i].id);
            if (findInResult) {
                mainArray[i].name = mainArray[i].name + " (" + findInResult.cnt + ")";
                timeArray.push(mainArray[i]);
            }
            i++;
        }
        return timeArray;
    }

    const changeHandler = (obj: Event): void => {
        const objTarget: any = obj.target;

        //взводим order
        setOrder((draft) => {
            if (objTarget.type === 'checkbox') {
                (draft as any)[objTarget.name] = !(draft as any)[objTarget.name];
            } else {
                (draft as any)[objTarget.name] = objTarget.value;
            }
            //снимаю блокировку на поля
            if (draft?.city_id > 0 && draft?.duration > 0 && draft.comm_lang_code && draft.comm_lang_code.length > 1 && draft.ts_from && draft.ts_from.length > 5) {
                setDisabledFields([]);
            } else {
                setDisabledFields(DFields);
            }

            return JSON.parse(JSON.stringify(draft));
        });
    }


    useEffect(() => {
        if (!orderId || orderId === "0") {
            setOrder((draft) => {
                if (contactId) {
                    draft.contact_id = parseInt(contactId);
                    draft.order_state_id = 1;
                    return JSON.parse(JSON.stringify(draft));
                }
            })
        } else {
            axios.get(API + '/Order/' + orderId + '/').then((response) => {
                if (response?.data) {
                    setOrder(response.data);
                    setDisabledFields([]);
                }
            }).catch((e) => {
                setError(true);
            });
        }

        //национальности
        axios.get(API + '/nationalities/').then((response) => {
            if (response?.data) {
                setNationality(response.data);
            }
        }).catch((e) => {
            setError(true);
        });
        //categories_list: []
        axios.get(API + '/Categories/').then((response) => {
            if (response?.data) {
                setCategories(response.data);
                setFilteredCategories(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //classes_list: []
        axios.get(API + '/Classes/').then((response) => {
            if (response?.data) {
                setClassses(response.data);
                setFilteredClasses(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //brands_list: []
        axios.get(API + '/brands/').then((response) => {
            if (response?.data) {
                setBrands(response.data);
                setFilteredBrands(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //models_list: []
        axios.get(API + '/models/').then((response) => {
            if (response?.data) {
                setModels(response.data);
                setFilteredModels(response.data)
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //transmissions_list: []
        axios.get(API + '/transmissions/').then((response) => {
            if (response?.data) {
                setTransmissions(response.data);
                setFilteredTransmissions(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //drive_types_list: []
        axios.get(API + '/drivetypes/').then((response) => {
            if (response?.data) {
                setDrive_types(response.data);
                setFilteredDrive_types(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //fuel_types_list: []
        axios.get(API + '/fueltypes/').then((response) => {
            if (response?.data) {
                setFuel_types(response.data);
                setFilteredFuel_types(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });

        //body_colors_list: []
        //interior_colors_list: []
        axios.get(API + '/colors/').then((response) => {
            if (response?.data) {
                setColors(response.data);
                setBodyColors(response.data);
                setInteriorColors(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //partners_list: []
        axios.get(API + '/Counterparties/').then((response) => {
            if (response?.data) {
                setPartners(response.data);
                //setFilteredPartners([]);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });


        //валюты
        axios.get(API + '/currencies/').then((response) => {
            if (response?.data) {
                setCurrencies(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });


        //языки
        axios.get(API + '/languages/').then((response) => {
            if (response?.data) {
                response.data.forEach((item: { id: any; code: any; }) => {
                    item.id = item.code;
                })
                setLanguages(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        //города
        axios.get(API + '/cities/').then((response) => {
            if (response?.data) {
                setCities(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });


    }, []);

    return (<>
        <div className={st.body_panel_heading}>
            <div className={'f24'}>Order</div>
            <div className={st.body_panel_heading_control}>
                {orderState.name}
            </div>
        </div>
        <div className={st.body_panel_body}>
            <div className={st.cube2}>
                <div>
                    <div className={st.form}>
                        {/*<div className={st.row + ' ' + st.row_compact}>*/}
                        {/*    <div className={st.formVertical}>*/}
                        {/*        <label>Contact*</label>*/}
                        {/*        <Input name={'first_name'} value={order?.contact_id}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={st.cube4}>
                            <div className={st.row + ' ' + st.row_compact}>
                                <div className={st.formVertical}>
                                    <label>From*</label>
                                    <Input name={'ts_from'} type={"datetime-local"} value={order?.ts_from}
                                           onChange={changeHandler} isReadOnly={order.order_state_id != WorkStatusID}/>
                                </div>
                            </div>
                            <div className={st.row + ' ' + st.row_compact}>
                                <div className={st.formVertical}>
                                    <label>Duration*</label>
                                    <Input name={'duration'} type={"text"} value={order?.duration}
                                           onChange={changeHandler} isReadOnly={order.order_state_id != WorkStatusID}/>
                                </div>
                            </div>
                            <div className={st.row + ' ' + st.row_compact}>
                                <div className={st.formVertical}>
                                    <label>City*</label>
                                    <CSelect name={'city_id'} value={order.city_id} values={cities} isSelect2={true}
                                             onChange={changeHandler}
                                             isReadOnly={order.order_state_id != WorkStatusID}/>
                                </div>
                            </div>
                            <div className={st.row + ' ' + st.row_compact}>
                                <div className={st.formVertical}>
                                    <label>Language:</label>
                                    <CSelect name={'comm_lang_code'} value={order.comm_lang_code} values={languages}
                                             isSelect2={true}
                                             onChange={changeHandler}
                                             isReadOnly={order.order_state_id != WorkStatusID}/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={st.cube2}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Pickup location:</label>
                                <Input name={'pickup_location'} value={order.pickup_location} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('pickup_location')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Drop location:</label>
                                <Input name={'drop_location'} value={order.drop_location} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('drop_location')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                    </div>
                    <div className={st.cube3}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Driver age:</label>
                                <Input name={'driver_age'} value={order.driver_age} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('driver_age')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Driver exp:</label>
                                <Input name={'driver_exp'} value={order.driver_exp} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('driver_exp')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>

                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Driver nationality:</label>
                                <CSelect value={order.driver_nationality_id} values={nationality}
                                         name={'driver_nationality_id'}
                                         onChange={changeHandler} isSelect2={true}
                                         isDisabled={disabledFields.includes('driver_nationality_id')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>

                            </div>
                        </div>
                    </div>
                    <div className={st.cube3}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Price from:</label>
                                <Input name={'price_from'} value={order.price_from} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('price_from')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Price to:</label>
                                <Input name={'price_to'} value={order.price_to} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('price_to')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Price currency:</label>
                                <CSelect name={'price_currency_id'} value={order.price_currency_id} values={currencies}
                                         isSelect2={true}
                                         onChange={changeHandler}
                                         isDisabled={disabledFields.includes('price_currency_id')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>


                    </div>
                    <div className={st.cube4}>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Engine from:</label>
                                <Input name={'engine_from'} value={order.engine_from} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('engine_from')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Engine to:</label>
                                <Input name={'engine_to'} value={order.engine_to} onChange={changeHandler}
                                       isDisabled={disabledFields.includes('engine_to')}
                                       isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Categories:</label>
                                <CSelect name={'categories_list'}
                                         value={order.categories_list ? order.categories_list : []}
                                         values={filteredCategories}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler}
                                         isDisabled={disabledFields.includes('categories_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Classes:</label>
                                <CSelect name={'classes_list'} value={order.classes_list ? order.classes_list : []}
                                         values={filteredClasses}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler} isDisabled={disabledFields.includes('classes_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Brands:</label>
                                <CSelect name={'brands_list'} value={order.brands_list ? order.brands_list : []}
                                         values={filteredBrands}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler} isDisabled={disabledFields.includes('brands_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Models:</label>
                                <CSelect name={'models_list'} value={order.models_list ? order.models_list : []}
                                         values={filteredModels}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler} isDisabled={disabledFields.includes('models_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Transmissions:</label>
                                <CSelect name={'transmissions_list'}
                                         value={order.transmissions_list ? order.transmissions_list : []}
                                         values={filteredTransmissions}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler}
                                         isDisabled={disabledFields.includes('transmissions_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Drive types:</label>
                                <CSelect name={'drive_types_list'}
                                         value={order.drive_types_list ? order.drive_types_list : []}
                                         values={filteredDrive_types}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler}
                                         isDisabled={disabledFields.includes('drive_types_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Fuel types:</label>
                                <CSelect name={'fuel_types_list'}
                                         value={(order.fuel_types_list) ? order.fuel_types_list : []}
                                         values={filteredFuel_types}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler}
                                         isDisabled={disabledFields.includes('fuel_types_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Seats:</label>
                                <CSelect name={'seats_list'} value={(order.seats_list) ? order.seats_list : []}
                                         values={seats}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler} isDisabled={disabledFields.includes('seats_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Body colors:</label>
                                <CSelect name={'body_colors_list'}
                                         value={(order.body_colors_list) ? order.body_colors_list : []}
                                         values={bodyColors as any}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler}
                                         isDisabled={disabledFields.includes('body_colors_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>
                        <div className={st.row + ' ' + st.row_compact}>
                            <div className={st.formVertical}>
                                <label>Interior colors:</label>
                                <CSelect name={'interior_colors_list'}
                                         value={(order.interior_colors_list) ? order.interior_colors_list : []}
                                         values={interiorColors as any}
                                         isSelect2={true} isMultiply={true}
                                         onChange={changeHandler}
                                         isDisabled={disabledFields.includes('interior_colors_list')}
                                         isReadOnly={order.order_state_id != WorkStatusID}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className={st.row + ' ' + st.row_compact}>
                        <div className={st.formVertical}>
                            <label>Partners:</label>
                            <div className={st.cube2}>
                                {filteredPartners && filteredPartners.map((item) => (
                                    <div key={(item as any).id} className={st.flex + ' ' + st.flexRight}>
                                        {(item as any).name}
                                        <Checkbox name={'partners_list[]'} onChange={(obj) => {
                                            setOrder((draft) => {
                                                if (!draft.partners_list) {
                                                    draft.partners_list = [];
                                                }
                                                let findInResult = draft.partners_list.findIndex((obj: any) => obj === (item as any).id);

                                                if (findInResult < 0) {

                                                    (draft.partners_list as any).push((item as any).id);
                                                } else {
                                                    (draft.partners_list as any).splice(findInResult, 1);
                                                }
                                                return JSON.parse(JSON.stringify(draft));
                                            })
                                        }}
                                                  isChecked={order?.partners_list?.find((obj: any) => obj === (item as any).id)}
                                                  isDisabled={order.order_state_id != WorkStatusID}/>
                                    </div>

                                ))}
                            </div>
                        </div>

                    </div>
                    <div className={st.row + ' ' + st.row_compact}>
                        <div className={st.formVertical}>
                            <label>vehicles </label>
                            <DataTable
                                columns={[
                                    {
                                        name: '#',
                                        id: 'id_',
                                        field: 'id'
                                    },
                                    {
                                        name: 'Car',
                                        id: 'carName_',
                                        render: (car: any) => {
                                            return car.brand_name + ',' + car.model_name + (car.extra_txt ? "," + car.extra_txt : "");
                                        }
                                    },
                                    {
                                        name: 'Year',
                                        id: 'year_',
                                        field: 'year'
                                    },
                                    {
                                        name: 'Price',
                                        id: 'client_price_',
                                        render: (car: any) => {
                                            return  (car.client_price?car.client_price:"") + " " + (order.price_currency_id * 1 > 0 ? (currencies.filter((i) => {
                                                return (i as any).id === order.price_currency_id
                                            })[0] as any).code : "choose currency")
                                        }
                                    },
                                ]}

                                rowsPerPage={7}
                                data={vehicles}
                                numPaginationButtons={5}
                                additionalButtons={true}
                                classForTable={st.body_panel_body}/>

                        </div>
                    </div>
                </div>
            </div>

            <div className={st.row + ' ' + st.flexRight}>
                <Modal isShowed={error} onClose={setError} withCloseBtn={true}>
                    {errorMessage}</Modal>
                {order.order_state_id == 1 ? (
                    <Button onClick={validateAndSubmit} type={"blank"} color={"blue"}>Save</Button>
                ) : <></>}
            </div>

        </div>
    </>);
}