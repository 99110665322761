import st from './CSelect.module.scss';
import {optionType} from "./types/optionTypes";
import React, {useEffect, useRef, useState} from "react";
import {isDisabled} from "@testing-library/user-event/dist/utils";

type CSelectType = {
    name?: string;
    onChange?: (obj: any) => void;
    values?: Array<optionType>;
    value?: string | Array<string> | number | null;
    hasError?: boolean;
    placeHolder?: string;
    isMultiply?: boolean;
    renderOptionValue?: any;
    isSelect2?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
}
type EventObjectType = {
    target: TargetType
}
type TargetType = {
    name: string,
    value: any,
}


const CSelect = ({
                     name = '',
                     onChange,
                     values,
                     value,
                     hasError,
                     placeHolder = '',
                     isMultiply = false,
                     renderOptionValue,
                     isSelect2 = false,
                     isDisabled = false, isReadOnly = false
                 }: CSelectType) => {

    let EventObject: EventObjectType = {target: {name: name, value: isMultiply ? [] : ""}};

    const handleOutsideClick = (e: any) => {
        let time: any = newRef.current;
        if (time && !time.contains(e.target)) {
            setOpen(false);
        }
    };
    const [isDown, setIsDown] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });
    useEffect(() => {
        setFullValues(values);
    }, [values]);
    const [fullValues, setFullValues] = useState(values);
    const [filteredValues, setFilteredValues] = useState(values);
    const newRef = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [filterText, setFilter] = useState('');
    const filterValue = () => {
        let time: any[] = [];
        fullValues?.forEach((item) => {
            if (item.name.toLowerCase().includes(filterText.toLowerCase())) {
                time.push(item);
            }
        })
        setFilteredValues(time);
    }

    const showedValue = () => {
        if (values) {
            if (Array.isArray(value)) {
                let val: string[] = [];
                values.forEach((item) => {
                    if (value?.includes(item.id) || value?.includes(item.id.toString())) {
                        renderOptionValue ? val.push(renderOptionValue(item)) : val.push(item.name);
                    }
                })
                return (
                    val.map((item) => {
                        return <span key={'_selected' + item} className={st.selectedItem}>{item}</span>;
                    })
                );
            } else {
                let val = '';
                values.forEach((item) => {
                    if (item.id === value) {
                        renderOptionValue ? val = renderOptionValue(item) : val = item.name;
                    }
                })
                return val;
            }
        } else {
            return '';
        }
    }

    return (
        <div className={st.root + ((hasError) ? ' ' + st.error : '') +' '+((isReadOnly || isDisabled)?st.off:"")} ref={newRef} onClick={(e) => {
            if (isReadOnly || isDisabled){ return false;}
            if (newRef?.current) {
                let oftop = (newRef.current as any).offsetTop;
                if (oftop > window.scrollY + window.innerHeight / 2) {
                    setIsDown(false);
                } else {
                    setIsDown(true);
                }
                //window.scrollY
                // window.innerHeight
            }
            if (e.target && isMultiply) {
                let t = e.target as HTMLElement;
                if (!t.classList.contains(st.option) && !isDisabled) {
                    setOpen(!isOpen);
                }
            } else {
                if (!isReadOnly && !isDisabled) {
                    setOpen(!isOpen);
                }
            }

            if (!isOpen) {
                setFilter('');
                setFilteredValues(fullValues);
            }
        }}>
            <div className={st.val + (isOpen ? ' ' + st.opened : '')}><span
                className={st.placeholder + (value !== '' ? ' ' + st.hidden : '')}>{placeHolder}</span>{showedValue()}
                {isSelect2 && isOpen ? (
                    <>
                        <input type={'text'} onChange={(obj) => {
                            setFilter(obj.target.value);
                            filterValue();
                        }} className={isMultiply?st.select2Input:st.selectInput}
                               autoFocus={true} value={filterText}/>
                    </>) : (<></>)}
            </div>


            <div
                className={st.selectOptions + ' ' + (isDown ? "" : st.selectOptions_up) + ' ' + (!isOpen ? st.hidden : '')}>
                <div className={st.option} key={'-'} onClick={() => {
                    EventObject.target.value = '-';
                    if (typeof onChange != "undefined") {
                        onChange(EventObject);
                    }
                }}>-
                </div>
                {filteredValues && filteredValues.length > 0 && filteredValues.map((item: optionType) =>
                    <div
                        className={st.option + ((Array.isArray(value) && (value.includes(item.id) || value?.includes(item.id.toString()))) ? ' ' + st.option_selected : "")}
                        key={item.id} onClick={() => {

                        if (typeof onChange != "undefined") {
                            if (!isMultiply) {
                                EventObject.target.value = item.id;
                                onChange(EventObject);
                            } else {
                                // console.log({filteredValues,value})
                                if (Array.isArray(value)) {
                                    let position = value.indexOf(item.id);
                                    if (position === -1) {
                                        value.push(item.id);
                                        EventObject.target.value = value;
                                    } else {
                                        value.splice(position, 1);
                                        EventObject.target.value = value;
                                    }
                                    onChange(EventObject);
                                }
                                if (typeof value ==="string"){
                                    EventObject.target.value = value;
                                    onChange(EventObject);
                                }
                            }
                        }
                    }}>
                        {renderOptionValue ? renderOptionValue(item) : item.name}
                    </div>
                )}
            </div>
        </div>
    )
}

export default CSelect;