import st from './Checkbox.module.scss';

type CheckboxType = {
    name?: string;
    placeholder?: string;
    value?: any;
    onChange?: (value: any) => void;
    isChecked?:boolean;
    isDisabled?: boolean;


}

function Checkbox({placeholder = '', value = '', onChange, name = '', isChecked=false, isDisabled=false}: CheckboxType) {
    return (
        <input type={'checkbox'} disabled={isDisabled} className={st.input} placeholder={placeholder} name={name} checked={isChecked} value={value}
               onChange={onChange}/>
    );
}

export default Checkbox