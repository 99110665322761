import {memo, useEffect} from "react";
import {useImmer} from "use-immer";
import st from "./Gallety.module.scss";
import SortableList, {SortableItem} from "react-easy-sort";
import {arrayMoveImmutable} from 'array-move'
import Button from "../Button/Button";

type GalleryType = {
    imgs?: Array<any>,
    onSort?: any,
    onChooseFiles?: any,
    checkImages?: any,
    mainImg?: string,
}


function Gallery({imgs = [], onSort, onChooseFiles, checkImages, mainImg = ''}: GalleryType) {

    const [images, setImage] = useImmer([...imgs]);
    const [checkedFiles, setChecked] = useImmer<string[]>([]);

    useEffect(() => {
        setImage(imgs as any);
    }, [imgs]);


    const onImageChange = (evt: any): any => {
        let target = evt.target, files: FileList[] = target.files;
        if (files && files.length) {
            let arrayOfUrl: any = [];
            Array.from(files).forEach((file: any) => {
                arrayOfUrl.push(URL.createObjectURL(file));
            })
            setImage([...imgs, ...arrayOfUrl] as any);
        }
        if (typeof onChooseFiles == "function") {
            onChooseFiles(files);
        }
    }
    const onSortEnd = (oldIndex: number, newIndex: number) => {
        let newArr = arrayMoveImmutable(images, oldIndex, newIndex);
        setImage(() => {
            return newArr;
        });
        if (typeof onSort == "function") {
            onSort(newArr);
        }
    };


    return (
        <div className={st.imgLine}>
            <SortableList
                onSortEnd={onSortEnd}
                className={st.imgLine}
                draggedItemClassName={st.dragged}
                allowDrag={true}
            >
                {
                    images.map((img, index) => {
                        return (
                            <SortableItem key={index}>
                                <div
                                    className={st.choosedImg +
                                        ' ' + (!imgs?.includes(img) ? st.time : "") +
                                        ' ' + (checkedFiles?.includes(img) ? st.checked : "") +
                                        ' ' + (img.includes(mainImg) ? st.mainImg : '')}
                                    style={{backgroundImage: `url(${img})`}} onClick={(e) => {
                                    let newCheckedFiles = [...checkedFiles];
                                    if (newCheckedFiles.includes(img)) {
                                        newCheckedFiles.splice(newCheckedFiles.indexOf(img), 1);
                                    } else {
                                        newCheckedFiles.push(img);
                                    }
                                    setChecked((draft => {
                                        return newCheckedFiles;
                                    }))
                                    if (typeof checkImages == "function") {
                                        checkImages(newCheckedFiles);
                                    }
                                }}>
                                </div>
                            </SortableItem>
                        );

                    })
                }
            </SortableList>
            <div className={st.chooseFile}>
                <input type="file" multiple={true} className={st.file} onChange={onImageChange} accept="image/*"/>
            </div>
        </div>

    );
}

export default memo(Gallery);