import st from './DataTable.module.scss';
import {useEffect, useState} from "react";
import Checkbox from "../Checkbox/Checkbox";
import {useImmer} from "use-immer";

type DataRow = { id?: any, name?: any | Array<any>, field?: string, render?: any, style?: object }
type DataTableTypes = {
    columns?: Array<DataRow> | null;
    data?: Array<{ id: any, [value: string]: any }> | null;
    classForTable?: string | null;
    classForPagination?: string | null;
    rowsPerPage?: number;
    numPaginationButtons?: number;
    additionalButtons?: boolean;
    isSelectRows?: boolean;
    onSelect?: (value: any) => void;
}

function DataTable({
                       columns = null,
                       data = null,
                       classForTable = null,
                       classForPagination = null,
                       rowsPerPage = 10,
                       numPaginationButtons = 5,
                       additionalButtons = false,
                       isSelectRows = false,
                       onSelect
                   }: DataTableTypes) {

    const consoleError = (errorField: string) => {
        console.log("Obj property " + errorField + " not found in obj Data");
        return '';
    }
    let numPages = 0;
    let totalPages = 0;
    if (data?.length) {
        totalPages = data.length / rowsPerPage;
        if (totalPages > numPaginationButtons) {
            numPages = numPaginationButtons;
        } else {
            numPages = totalPages;
        }
    }
    const getPaginationButtons = (startRow: number) => {


        if (numPages > 0) {
            let content: any = [];
            content.push(
                <div className={st.btn + ' ' + st.btnActive} key={'page_' + startRow}>{startRow + 1}</div>
            );
            for (let i = 0; content.length <= numPages - 1; i++) {

                if ((startRow - i - 1) >= 0) {
                    content.unshift(<div className={st.btn} onClick={() => setPage((startRow - i - 1))}
                                         key={'page' + (startRow - i - 1)}>{(startRow - i)}</div>);
                }

                if ((totalPages + 1) > (startRow + i + 2)) {
                    content.push(<div className={st.btn} onClick={() => setPage((startRow + i + 1))}
                                      key={'page_' + (startRow + i + 1)}>{(startRow + i + 2)}</div>);
                }
            }
            if (additionalButtons && startRow > 0) {
                content.unshift(<div className={st.btn} onClick={() => setPage((startRow - 1))}
                                     key={'page_as_text' + (startRow - 1)}>prev</div>);
            }
            if (additionalButtons && (totalPages + 1) > (startRow + 2)) {
                content.push(<div className={st.btn} onClick={() => setPage((startRow + 1))}
                                  key={'page_as_text' + (startRow + 1)}>next</div>);
            }
            return content;
        } else {
            return [];
        }


    }
    const startSelectedRows: Array<any> = [];
    const [startRow, setPage] = useState(0);
    const [selectedRows, setSelected] = useImmer(startSelectedRows);
    const toggleAll = () => {
        if (selectedRows.length > 0) {
            setSelected([]);
        } else {
            setSelected((draft) => {
                data?.slice(startRow * rowsPerPage, (startRow * rowsPerPage) + rowsPerPage).map((item) => {
                    draft.push(item.id);
                });
            });
        }
    }
    const toggleRow = (rowId: any) => {
        setSelected((draft) => {
            let indexInArray = draft.indexOf(rowId);
            if (indexInArray >= 0) {
                draft.splice(indexInArray, 1);
            } else {
                draft.push(rowId);
            }
        });
    }
    useEffect(() => {
        if (onSelect) {
            onSelect(selectedRows);
        }
    }, [selectedRows]);

    return (
        <div className={st.wrapper} key={'datatable'}>
            <div className={(classForTable !== null) ? classForTable : ''}>
                <table className={st.table}>
                    {(columns !== null) ? (
                        <>
                            <thead>
                            <tr>
                                {isSelectRows ? (
                                    <th key={"CHECKED_ALL_TH"} className={st.checkColumn}>
                                        <Checkbox name={'checkAll'} onChange={toggleAll}
                                                  isChecked={selectedRows.length > 0}/>
                                    </th>
                                ) : (<></>)}
                                {columns.map((item, index) =>
                                    (typeof item.name == "string") ? (
                                            <th key={"TH__" + (typeof item.id === "undefined") ? index : item.id}
                                                style={item.style ? item.style : {}}>{item.name}</th>) :
                                        (typeof item.name == "object") ? (
                                            item.name.map((field: DataRow, idx: any) =>
                                                (
                                                    <th key={"TH___" + (typeof item.id === "undefined") ? idx : item.id}
                                                        style={field.style ? field.style : {}}>{field.name}</th>
                                                ))

                                        ) : (<></>)
                                )
                                }
                            </tr>
                            </thead>
                            {(data !== null) ?
                                <tbody>
                                {data && data?.slice(startRow * rowsPerPage, (startRow * rowsPerPage) + rowsPerPage).map((item, indx) => (
                                    <tr key={"TR-" + item.id + indx}>
                                        {isSelectRows ? (
                                            <td key={"TD____" + (typeof item.id === "undefined") ? indx : item.id + "_" + item.id}>
                                                <Checkbox name={'check'} value={item.id}
                                                          onChange={() => toggleRow(item.id)}
                                                          isChecked={selectedRows.indexOf(item.id) >= 0}/>
                                            </td>
                                        ) : (<></>)}
                                        {columns.map((column, index) => (
                                            (typeof column.name == "string") ?
                                                (typeof column.render !== "undefined" || column.field === undefined) ? (
                                                    <td key={"TD_" + (typeof item.id === "undefined") ? index : item.id + "__TD" + "0"}>{column.render(item)}</td>) : (
                                                    <td key={"TD_" + (typeof item.id === "undefined") ? index : item.id + "_TD" + "1"}
                                                        dangerouslySetInnerHTML={{__html: ((item && typeof item[column.field] !== "undefined") ? item[column.field] : consoleError(column.field))}}></td>

                                                )
                                                :
                                                (typeof column.render !== "undefined" || column.field === undefined) ? (
                                                    column.name.map((field: DataRow, idx: any) =>
                                                        (
                                                            <td key={"TD_" + (typeof item.id === "undefined") ? idx : item.id + "___TDD"}>{column.render(item, field)}</td>
                                                        ))
                                                ) : (
                                                    <td key={"TD_" + item.id + "___TDDD"}
                                                        dangerouslySetInnerHTML={{__html: ((item && typeof item[column.field] !== "undefined") ? item[column.field] : consoleError(column.field))}}></td>

                                                )

                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                                : ''}
                        </>
                    ) : (
                        <>
                            {(data !== null) ?
                                <tbody>
                                {data !== null && data.map((item, index) => (
                                    <tr key={"TR" + item.id + "+" + index}>
                                        {isSelectRows ? (
                                            <td key={"TDDDD" + item.id}>
                                                <Checkbox value={item.id} name={'check'}
                                                          onChange={() => toggleRow(item.id)}
                                                          isChecked={selectedRows.indexOf(item.id) >= 0}/>
                                            </td>
                                        ) : (<></>)}
                                        <td key={"TDD" + item.id}>{item.id}</td>
                                        <td key={"TDDD" + item.id}>{item.value}</td>
                                    </tr>
                                ))}
                                </tbody>
                                : ''}
                        </>
                    )
                    }
                </table>
            </div>
            <div
                className={(st.pagination ? st.pagination : '') + ' ' + (classForPagination != null ? classForPagination : '')}>
                <div>
                    Show {startRow * rowsPerPage}-{(startRow * rowsPerPage + rowsPerPage > (data as any).length)? data?.length : startRow * rowsPerPage + rowsPerPage} from {data?.length}
                </div>
                <div className={st.btnList}>
                    {getPaginationButtons(startRow)}
                </div>
            </div>
        </div>);

}

export default DataTable;