import React, {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../const/API";
import st from "../styles/template.module.scss";
import {IOrder} from "../types/Order";
import DataTable from "../components/DataTable/DataTable";
import icoEdit from "./../imgs/Icon-Edit.svg";
import {NavLink} from "react-router-dom";
import {IOrderState} from "../types/OrderState";
import icoSearch from '../imgs/search-normal.svg';
import {IContact} from "../types/Contact";

export default function Orders() {
    const [orders, setOrders] = useState<Array<IOrder>>([]);
    const [orderStates, setOrderStates] = useState(Array<IOrderState>);
    const [contacts, setContacts] = useState(Array<IContact>);

    const [error, setError] = useState(false);
    useEffect(() => {
        axios.get(API + '/contacts/').then((response) => {
            if (response?.data) {
                setContacts(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        });
        axios.get(API + '/OrderStates/').then((response) => {
            if (response?.data) {
                setOrderStates(response.data);
                // console.log(response.data)
            }
        }).catch((e) => {
            setError(true);
        }).finally(() => {
            axios.get(API + '/orders/').then((response) => {
                if (response?.data) {
                    setOrders(response.data);
                    // console.log(response.data)
                }
            }).catch((e) => {
                setError(true);
            });
        })
    }, [])
    return (<>
            <div className={st.body_panel_heading}>
                <div className={'f24'}>Orders</div>
                <div className={st.body_panel_heading_control}>
                    {/*<Link to={'/orderForm'}><Button color={'blue'} type={'plus'}>Add order</Button></Link>*/}
                </div>
            </div>
            <div className={st.body_panel_body}>

            </div>
            <DataTable
                columns={[
                    {
                        name: '#',
                        id: 'id_',
                        field: 'id'
                    },
                    {
                        name: 'Contact',
                        id: 'contact_id_',
                        render: (row: any) => {
                            let r = 'unknown';
                            contacts.forEach((i) => {
                                if (i.id == row.contact_id) {
                                    r = i.first_name ?? i.first_name + " " + i.middle_name ?? i.middle_name + " " + i.last_name ?? i.last_name;
                                }
                            })
                            return r;
                        }
                    },
                    {
                        name: 'From',
                        id: 'ts_from_',
                        field: "ts_from"
                    },


                    {
                        name: 'Duration',
                        id: 'duration_',
                        field: "duration"
                    },
                    {
                        name: 'Status',
                        id: 'status_',
                        render: (row: any) => {
                            let r = 'unknown';
                            orderStates.forEach((i) => {
                                if (i.id == row.order_state_id) {
                                    r = i.name;
                                }
                            })
                            return r;
                        }
                    },
                    {
                        name: 'Control',
                        id: 'control_',
                        render: (row: any) => {
                            if (row.order_state_id == 1) {
                                return <NavLink to={'/orderForm/' + row.id + '/' + row.contact_id}><img src={icoEdit}
                                                                                                        alt={'edit'}/></NavLink>; ////orderForm/0/5
                            } else {
                                return <NavLink to={'/orderShow/' + row.id + '/'}><img src={icoSearch}
                                                                                       alt={'show'}/></NavLink>;
                                ;
                            }
                        }
                    }
                ]}

                rowsPerPage={50}
                data={orders}
                numPaginationButtons={5}
                additionalButtons={true}
                classForTable={st.body_panel_body}/>
        </>
    )

}